swiper-container {
  width: 80px;
  height: 170px;
}

swiper-slide {
  text-align: center;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.25;
  user-select: none;
  transition: opacity 0.3s ease;
  cursor: default;
  font-weight: bold;
}

swiper-slide.swiper-slide-active {
  opacity: 1;
}

.swiper-slide-prev,
.swiper-slide-next {
  cursor: pointer;
}

.border-timepicker {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 1rem;
  right: 1rem;
  transform: translateY(-50%);
  font-size: 2rem;
  line-height: 62px;
}

.border-timepicker:before,
.border-timepicker:after {
  content: ":";
  display: inline-block;
  line-height: inherit;
  height: 100%;
  position: absolute;
  /* top: 0;
  left: 65px; */
  transform: translateX(-50%);
}

.border-timepicker:before {
  left: 65px;
}

.border-timepicker:after {
  left: 145px;
}
